import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';

const resume = require('../../../../../Files/Resume - 10.24.18.pdf');

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  div: {
    paddingTop: theme.spacing.unit * 2,
  },
});

class Information extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.div }>
        <Paper className={ classes.root }>
          <Typography paragraph>
            To get in contact with me, please use the email form,
            or if you wish to send me an email directly, you can do so at <a
            href="mailto:kim.jongjhun@gmail.com">kim.jongjhun@gmail.com</a>
          </Typography>
          <Typography>
            More details about me can be seen on my <a href={ 'https://www.linkedin.com/in/jong-jhun-kim-a245a5119/' }
                                                       target={ '_blank' }>
            LinkedIn.
          </a>
          </Typography>
          <Typography paragraph>
            Code samples can be seen at my <a href={ 'https://github.com/kimjongjhun' } target={ '_blank' }>GitHub.</a>
          </Typography>
          <Typography>
            The most updated copy of my resume can be downloaded <a href={ resume } target={ '_blank' }>here</a>
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Information);
