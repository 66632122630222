export const factsList = [
  {
    label: 'Location',
    value: 'Brandon, FL'
  },
  {
    label: 'Email Address',
    value: 'Kim.JongJhun@gmail.com'
  },
  {
    label: 'Phone Number',
    value: '(813)-365-7694'
  },
];