/**
 * Add each new employment as an array, the most recent at the top.
 * The first object in the array should be the name and location.
 * Any other objects in the array should be information regarding that employment.
 */

export const employmentList = {
  flex: [
    {
      name: 'FlexShopper, Inc.',
      location: 'Boca Raton, FL (Remote)',
    },
    {
      startDate: 'Sept. 2017',
      endDate: 'Oct. 2018',
      position: 'Software Developer ',
      description: [
        '● Maintained AngularJS 1.5 code that is v1 of an internal tool used by the Customer Service department.',
        '● Built in React that is v2 of the same internal tool.',
        '● Made components in both AngularJS and React.',
      ]
    },
  ],
  ussi: [
    {
      name: 'United Systems and Software, Inc.',
      location: 'Lake Mary, FL',
    },
    {
      startDate: 'Mar. 2017',
      endDate: 'May 2017',
      position: 'Software Developer ',
      description: [
        '● Modernizing software using AngularJS 1.X and Bootstrap 3 from GreenScreen RPG format.',
        '● Made prototypes and proof of concepts of modernized and converted software.',
        '● Responsible for creating an entirely new UI from the ground up.',
        '● Made a GitLab server for a team of 10+ developers.',
      ]
    },
  ],
  dollarStore: [
    {
      name: 'All in One Superstore',
      location: 'Casselberry, FL',
    },
    {
      startDate: 'Aug. 2012',
      endDate: 'Mar. 2016',
      position: 'Manager ',
      description: [
        '● Counted cash drawers and made bank deposits.',
        '● Interviewed job candidates and made staffing decisions.',
        '● Planned budgets and authorized payments and merchandise returns.',
        '● Completed profit and loss performance reports.',
      ]
    },
    {
      startDate: 'Aug. 2010',
      endDate: 'Aug. 2012',
      position: 'Cashier ',
      description: [
        '● Maintained records related to sales.',
        '● Stocked shelves and supplies and organized displays.',
      ]
    },
  ],
};